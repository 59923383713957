import { Unity, useUnityContext } from "react-unity-webgl";
import { useEffect, useState, Fragment, useRef } from "react";
import LiveChat from './LiveChat/LiveChat';
import { PartiesUi } from "./PartiesUi";
import { ReactUnityEventParameter } from "react-unity-webgl/distribution/types/react-unity-event-parameters";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setUsers, User } from "../store/usersOnline";
import LoadingPage from "./LoadingPage/LoadingPage";
import { UpdateAvatarModal } from "./ReadyPlayerMe/UpdateAvatarModal";
import { setId, setPlayer, setSessionId, setUpdate } from "../store/appUser";
import { setWebsocket } from "../store/debug";
import { setMessageHistory } from "../store/messages";
import { globalIsHost } from "./InboundLinkRouting";

type Props = {
  id: string | undefined
}
export interface RouteParams extends Record<string, string | undefined> {
  id: string;
}
export const GlobalRefs = {
  sendMessage: null as ReactUnityEventParameter | null,

}

type UnityConfig = {
  readonly streamingAssetsUrl?: string;
};

type disconnect = {
  timestamp: string,
  playerId: string
}

export const UnityClient: React.FC<Props> = ({ id }) => {

  const colors = ["#FF6F61", "#FF8A33", "#FFA033", "#FFC133", "#FFE033", "#FFFF33", "#DFFF33", "#B3FF33", "#8CFF33",
    "#61FF33", "#33FF4F", "#33FF80", "#33FFA8", "#33FFD1", "#33FFFF", "#33D1FF", "#33A8FF", "#D133FF", "#FF33FF",
    "#FF33D1", "#FF33A8", "#FF3380", "#FF4F33", "#FF6F33", "#FF8A00", "#FFBF00", "#FFD700", "#FFF700", "#CCFF00",
    "#99FF00", "#66FF33", "#33FF66", "#33FF99", "#33FFCC", "#33F7FF", "#33DFFF", "#33BFFF", "#BF33FF", "#E033FF",
    "#FF33F7", "#FF33CC", "#FF3399", "#FF3370", "#FF5733", "#FF6A33", "#FF8547", "#FFA366", "#FFC385", "#FFE3A3",
    "#FFFFA0", "#DFFF99", "#BFFF8C", "#8CFF99", "#99FFB3", "#99FFD1", "#99FFFF", "#FF9999", "#FFB399", "#FFD699",
    "#FFE699", "#FFFF99", "#BFFF99", "#8CFFB3", "#66FF99", "#66FFD1", "#66FFFF", "#66D1FF", "#66A8FF", "#66B3FF",
    "#66DFFF", "#99B3FF", "#B399FF", "#CC99FF", "#E099FF", "#F7CCFF", "#FFCCF7", "#FFC1E3", "#FFB3D1", "#FF99A8",
    "#FF9999", "#FFCC99", "#FFD1B3", "#FFE6CC", "#FFCC66", "#FF9933", "#FF8000", "#FFA600", "#FFCD00", "#FFE033",
    "#FFF033", "#FFFF66", "#CCFF66", "#BFFF66"];

  const { index, moduleId } = useAppSelector(state => state.scenes)



  const dispatch = useAppDispatch()
  const { player, update } = useAppSelector(state => state.appUser)
  const { messageHistory } = useAppSelector(state => state.messages)
  const [showUnityClient, setShowUnityClient] = useState(true);
  const [websocketOpen, setWebsocketOpen] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [room, setRoom] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false)
  const { users } = useAppSelector((state) => state.usersOnline)
  const usersRef = useRef(users)
  const updateRef = useRef(update)
  const messageHistoryRef = useRef(messageHistory)
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const showProfileRef = useRef(showProfile)
  const playerRef = useRef(player)
  const [unityInstance, setUnityInstance] = useState<any>(null);


  //Update refs
  useEffect(() => {
    usersRef.current = users
    updateRef.current = update
    messageHistoryRef.current = messageHistory
    showProfileRef.current = showProfile
    playerRef.current = player
  }, [users, update, messageHistory, showProfile, player])


  useEffect(() => {
    const handleUnityMessage = (event: MessageEvent) => {

      if (event.origin !== window.location.origin) return;

      if (event.data.type === "UNITY_INSTANCE_READY") {
        console.log("Unity instance is ready!");
        iFramePostMessage("ReactClientManager", "SetSTOMPConnectHeaders", JSON.stringify({
          authToken: playerRef.current.authToken,
          sceneId:moduleId
        }))
        setUnityInstance(event.data.unityInstance);
        iFramePostMessage("IFrameContentUpdate", "SetIsHost", globalIsHost.isHost)
        initialiseUnity()

      }
    };

    window.addEventListener("message", handleUnityMessage);

    return () => {
      window.removeEventListener("message", handleUnityMessage);
    };
  }, []);

  

  function initialiseUnity() {
    console.log("Initialising unity")
    let data = {
      displayName: playerRef.current.displayName,
      avatarUrl: playerRef.current.glbUrl,
      colour: playerRef.current.colour === '#fff' || playerRef.current.colour === null ? colors[Math.floor(Math.random() * colors.length)] : playerRef.current.colour
    }

    iFramePostMessage("ReactClientManager", "OnAvatarCreationCompleted", null)
      if (id === "bb9fd5bd-fd80-4524-8355-8c765d5510d8") {
        let stateToUpdate = {
          ...playerRef.current,
          glbUrl: "cba6e927-9de4-4e03-a190-df9262359f14",
          displayName: "Soledad"
        };
        dispatch(setPlayer(stateToUpdate))
        data.avatarUrl = "cba6e927-9de4-4e03-a190-df9262359f14"
        data.displayName = "Soledad"
      }
      console.log("UNITY DATA")
      console.log(data)


      iFramePostMessage("ReactClientManager", "OnAvatarCreationCompleted", null)
      setTimeout(() => {
        iFramePostMessage("ReactClientManager", "SetLocalAvatar", JSON.stringify(data))
      }, 4000)
  }

  useEffect(() => {
    window.sendMessage = iFramePostMessage
  });


  function handleProfileClick(){
   
    console.log("Profile button clicked");
    if (
      id !== "bb9fd5bd-fd80-4524-8355-8c765d5510d8" &&
      !player.glbUrl?.endsWith(".vrm")
    ) {
      setShowProfile(!showProfileRef.current);
    }
  };

  // handle Subscriptions
  function subscribeToUpdateAndDisconnect(room: string) {
    iFramePostMessage("ReactClientManager", "SubscribeToSTOMPTopic", "/topic/update-avatar/" + room, "STOMP_AVATAR_UPDATE")
    iFramePostMessage("ReactClientManager", "SubscribeToSTOMPTopic", "/topic/disconnect-user/" + room, "STOMP_DISCONNECT")
    iFramePostMessage("ReactClientManager", "SubscribeToSTOMPTopic", "/topic/chat", "STOMP_GLOBAL_CHAT")
    iFramePostMessage("ReactClientManager", "SubscribeToSTOMPTopic", "/topic/chat/" + room, "STOMP_ROOM_CHAT")
    iFramePostMessage("ReactClientManager", "SubscribeToSTOMPTopic", "/user/queue/party", "STOMP_PARTY")
  }

  // update avatars online
  function updateAvatars(avatars: User[]) {
    console.log(avatars)

    let currentUsers = [...usersRef.current]
    console.log(currentUsers)
    console.log(playerRef.current.colour)
    console.log(playerRef.current.glbUrl)
    console.log(playerRef.current.displayName)
    
    avatars.forEach((user: User) => {
      const existingUserIndex = currentUsers.findIndex(existingUser => existingUser.playerId === user.playerId);

      if (existingUserIndex === -1) {
        currentUsers.push(user);
      } else {
        currentUsers[existingUserIndex] = user;
      }
      console.log(user.avatarUrl === playerRef.current.glbUrl)
      console.log(user.displayName === playerRef.current.displayName)
      console.log(user.colour === playerRef.current.colour)
   
      if (user.avatarUrl === playerRef.current.glbUrl && user.displayName === playerRef.current.displayName && user.colour === playerRef.current.colour) {
        console.log("Setting PlayerId: " + user.playerId)
        dispatch(setId(user.playerId))
      }
      let updatedMessages = messageHistoryRef.current.map(message => {
        if (message.playerId === user.playerId) {
          return { ...message, from: user.displayName };
        }
        return message;
      });

      dispatch(setMessageHistory(updatedMessages));
    })
    dispatch(setUsers(currentUsers))
    dispatch(setUpdate(!updateRef.current))
  }

  // handle Disconnects
  function disconnectAvatars(avatars: disconnect[]) {
    let currentUsers = [...usersRef.current]
    const disconnectIds = new Set(avatars.map(avatar => avatar.playerId));
    currentUsers = currentUsers.filter(user => !disconnectIds.has(user.playerId));
    dispatch(setUsers(currentUsers))
  }


  // post Messages to the iFrame
  function iFramePostMessage(to: string, type: string, data: any | null, stomptype?:string) {
    const iframe = document.getElementById('unityIframe') as HTMLIFrameElement | null;

    if (iframe && iframe.contentWindow) {

      const msg = { to: to, type: type, data: data, stomptype: stomptype };
      iframe.contentWindow.postMessage(msg, '*');
    } else {
      console.error('Iframe not found or contentWindow is null');
    }
  }

  // Recieve Messages from the iFrame
  const handleIframeMessage = (event: MessageEvent) => {
    const data = event.data;
    console.log('Message received from iframe:', data);
    switch (data.type) {
      case "STOMP_INITIALISE":
        console.log(data.frame)
        setRoom(data.frame.body.room)
        subscribeToUpdateAndDisconnect(data.frame.body.room)
        updateAvatars(data.frame.body.avatarDefinitions)
        dispatch(setWebsocket("Connected"))
        dispatch(setSessionId(data.frame.body.sessionId))
        break;
      case "STOMP_DISCONNECT":
        disconnectAvatars(data.frame.body)
        break;
      case "STOMP_AVATAR_UPDATE":
        updateAvatars(data.frame.body)
        break;
      case "PROFILE_CLICKED":
        handleProfileClick()
        break;
      default:
        break;
    }
  };

  // setup listener for messages
  useEffect(() => {
    window.addEventListener('message', handleIframeMessage);

    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);


  return (
    <Fragment>
      {showUnityClient && (
        <div style={{ position: "relative", width: "100vw", height: "100vh" }}>
          <iframe
            onLoad={() => setIsLoaded(true)}
            ref={iframeRef}
            src={index + '?show_call_ui=true'}
            width="100vw"
            height="100vh"
            title="Example Website"
            id="unityIframe"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 0,
              pointerEvents: 'auto'
            }}
          />

          {isLoaded && (<PartiesUi frame={iframeRef.current}/>)}
          {room && (
            <div>
              <LiveChat room={room} iFramePostMessage={iFramePostMessage}/>
            </div>
          )}
          <UpdateAvatarModal
            setOpen={setShowProfile}
            open={showProfile}
            sendMessage={iFramePostMessage}
          />
        </div>
      )}
    </Fragment>
  )
}